<template>
  <zy-layout direction="right">
    <!-- 左边布局 -->
    <zy-layout-column animation="left">
      <zy-title>里程碑完成</zy-title>
      <zy-carousel-box class="carousel" :list="milestonesList" :notOpacity="true" :moveUnit="310">
        <template #customSlot="scope">
          <el-row>
            <el-col :span="12">
              <div class="jxyh-flex-v lcb">
                <div class="lcb-left-box">计划开始</div>
                <div class="lcb-right-box">{{ scope.row.startDate || '——' }}</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="jxyh-flex-v lcb">
                <div class="lcb-left-box">计划结束</div>
                <div class="lcb-right-box">{{ scope.row.endDate || '——' }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 10px">
            <el-col :span="12">
              <div class="jxyh-flex-v lcb">
                <div class="lcb-left-box">实际开始</div>
                <div class="lcb-right-box">{{ scope.row.actualStartDate || '——' }}</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="jxyh-flex-v lcb">
                <div class="lcb-left-box">实际结束</div>
                <div class="lcb-right-box">{{ scope.row.actualEndDate || '——' }}</div>
              </div>
            </el-col>
          </el-row>
        </template>
      </zy-carousel-box>
      <zy-title class="jxyh-margin" @more="showGantDialog">进度展示</zy-title>
      <div class="marginTop10" style="height: 540px">
        <gant-table :tableWidth="530"></gant-table>
        <zy-dialog class="zy-dialog" :visible="gantVisible" :title="'进度展示'" width="86%" @close="closeGantDialog">
          <gant-table :tableWidth="890" :displayAll="true" class="dialogGant"></gant-table>
        </zy-dialog>
      </div>
    </zy-layout-column>
    <!-- 右边布局 -->
    <zy-layout-column animation="right">
      <zy-title>形象进度</zy-title>
      <video v-if="videoUrl" autoplay muted loop class="marginTop10" style="height: 247px;object-fit: fill;" id="myVideo" width="100%" controls>
        <source :src="videoUrl" type="video/mp4" />
      </video>
      <zy-title class="jxyh-margin" @more="showSourceDialog">资源分布</zy-title>
      <span-table class="marginTop10" :sourceList="distributionList.slice(0,13)"></span-table>
      <zy-dialog :visible="sourceVisible" :title="'资源发布'" @close="closeDialog">
        <span-table class="marginTop10" :sourceList="distributionList"></span-table>
      </zy-dialog>
    </zy-layout-column>
  </zy-layout>
</template>

<script>
import components from '@/view/jxyh/components'
import { getProjectTarget } from '@/jxyh/api/centerManger'
import { getQualityHighLight } from '@/jxyh/api/quality'
import { getDistributionList } from '@/jxyh/api/progressManager'
import SpanTable from './components/span-table.vue'
import GantTable from './components/gant-table.vue'
export default {
  ...components(['ZyLayout', 'ZyLayoutColumn', 'ZyTitle', 'ZyCarouselBox', 'ZyThree', 'ZyGant', 'ZyDialog'], { SpanTable, GantTable }),
  data() {
    return {
      milestonesList: [],
      distributionList: [], //资源发布
      videoUrl: '',
      sourceVisible: false,
      gantVisible: false,
    }
  },
  mounted() {
    this.getLCB()
    this.getVideoUrl()
    this.getDistributionList()
  },
  methods: {
    getLCB() {
      getProjectTarget().then((res) => {
        this.milestonesList = res.data.milestonesList
      })
    },
    getVideoUrl(url) {
      getQualityHighLight({ viewEnum: 4 }).then((res) => {
        this.videoUrl = res.data[0].videoUrl
      })
    },
    getDistributionList() {
      getDistributionList().then((res) => {
        this.distributionList = res.data
      })
    },
    showGantDialog() {
      this.gantVisible = true
    },
    closeGantDialog() {
      this.gantVisible = false
    },
    showSourceDialog() {
      this.sourceVisible = true
    },
    closeDialog() {
      this.sourceVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.marginTop10 {
  margin-top: 10px;
}
.lcb {
  font-family: SourceHanSansCN, SourceHanSansCN;
  &-left-box {
    width: 40px;
    height: 40px;
    color: #66bbf9;
    font-size: 12px;
    padding: 7px;
    box-sizing: border-box;
    background: linear-gradient(180deg, rgba(28, 47, 99, 0) 0%, rgba(0, 233, 255, 0.24) 100%);
    border: 1px solid;
    border-image: linear-gradient(185deg, rgba(24, 123, 205, 0), rgba(51, 216, 250, 0.6)) 1 1;
  }
  &-right-box {
    width: 94px;
    color: #ffffff;
    padding: 0 10px;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 0px;
  }
}
.carousel {
  margin-top: 30px;
  /deep/ .left-btn {
    margin-right: 5px !important;
  }
  /deep/ .right-btn {
    margin-left: 5px !important;
  }
  /deep/ .carousel-box-content {
    width: 100%;
    height: 226px !important;
    .group {
      gap: 7px !important;
      .item {
        width: 303px !important;
        height: 226px !important;
        gap: 10px !important;
        background: url('../../assets/images/img_kk.png') !important;
        .title {
          margin-bottom: 10px;
        }
      }
    }
  }
}
.zytable {
  /deep/ .el-table::before {
    height: 0;
  }
}
.dialogGant{
  /deep/ .zy-gant{
    height: 540px; 
  }
}
</style>
