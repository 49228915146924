import { get, post } from "@/utils/request"

// 资源分布
export function getDistributionList(params) {
  return get("/pms/resource/list", params)
}

// 进度展示-第一层
export function getProgressFirstLevel(params) {
  return get("/pms/view/progress/oneList", params)
}

// 进度展示-子层
export function getProgressChild(params) {
    return get("/pms/view/progress/currentCode", params)
  }

