<template>
  <div style="height: 100%;">
    <zy-gant :tableWidth="tableWidth" :request="load">
      <el-table-column label="任务名称" prop="taskName" show-overflow-tooltip minWidth="290"></el-table-column>
      <el-table-column label="状态" prop="status" align="center" width="90">
        <template slot-scope="{ row }">
          <div class="cell jxyh-flex-v" style="width: 70px">
            <div :style="{ background: returnColor(row.status) }" class="circle"></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="计划开始" align="center" prop="planStartDate" minWidth="120">
        <template slot-scope="scope">
          {{ formatDate(scope.row.planStartDate) }}
        </template>
      </el-table-column>
      <el-table-column v-if="displayAll" align="center" label="计划结束" prop="planEndDate" minWidth="120">
        <template slot-scope="scope">
          {{ formatDate(scope.row.planEndDate) }}
        </template>
      </el-table-column>
      <el-table-column v-if="displayAll" align="center" label="实际开始" prop="actualStartDate" minWidth="120">
        <template slot-scope="scope">
          {{ formatDate(scope.row.actualStartDate) }}
        </template>
      </el-table-column>
      <el-table-column v-if="displayAll" align="center" label="实际结束" prop="actualEndDate" minWidth="120">
        <template slot-scope="scope">
          {{ formatDate(scope.row.actualEndDate) }}
        </template>
      </el-table-column>
    </zy-gant>
    <div class="legend jxyh-flex-v">
      <div class="jxyh-flex-v legend-item" v-for="item in legendList" :key="item.name">
        <div class="circle" :style="{ background: item.color }"></div>
        <span class="legend-text">{{ item.name }}</span>
      </div>
      <div class="line"></div>
      <div class="jxyh-flex-v" style="margin-left: 30px">
        <div class="legend-rect" style="background: #ffb822"></div>
        <span class="legend-text">实际时长</span>
      </div>
      <div class="jxyh-flex-v" style="margin-left: 30px">
        <div class="legend-rect" style="background: #0360f5"></div>
        <span class="legend-text">计划时长</span>
      </div>
    </div>
  </div>
</template>

<script>
import components from '@/view/jxyh/components'
import moment from 'moment'
import {  getProgressFirstLevel, getProgressChild } from '@/jxyh/api/progressManager'
export default {
  ...components(['ZyGant']),
  data() {
    return {
      legendList: [
        { name: '进行中', color: '#66BBF9' },
        { name: '按时完成/提前完成', color: '#63E881' },
        { name: '延迟进行中', color: '#FC4A66' },
        { name: '延迟完成', color: '#9042FF' },
        { name: '延迟未开始', color: '#FFB822' },
        { name: '未开始', color: '#B1B1B1' }
      ],
      statusList: [
        { name: '进行中', color: '#66BBF9' },
        { name: '按时完成', color: '#63E881' },
        { name: '提前完成', color: '#63E881' },
        { name: '延迟进行中', color: '#FC4A66' },
        { name: '延迟完成', color: '#9042FF' },
        { name: '延迟未开始', color: '#FFB822' },
        { name: '未开始', color: '#B1B1B1' }
      ]
    }
  },
  props: {
    //数据列表
    list: {
      type: Array,
      default: () => [],
    },
    tableWidth: {
      type: Number,
      default: 530
    },
    displayAll: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatDate(date) {
      if(!date) return '——'
      return moment(date).format('YYYY-MM-DD')
    },
    returnColor(status) {
      let index = this.statusList.findIndex(p => p.name == status)
      return this.statusList[index].color
    },
    load(tree) {
      if (!tree) return getProgressFirstLevel()
      return getProgressChild({ currentCode: tree.outlineCode })
    }
  }
}
</script>

<style lang="less" scoped>
.circle {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin: 4px 6px;
}
.line{
  width: 1px;
  height: 14px;
  background: #FFFFFF;
  opacity: 0.2;
  margin: 0 30px 0 36px;
}
.legend{
  padding: 10px;
  font-size: 12px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  color: #FFFFFF;
  justify-content: flex-start;
  &-item{
    margin-right: 24px;
  }
  &-rect{
    width: 10px;
    height: 10px;
    border-radius: 2px;
    margin: 4px 6px;
  }
}
</style>