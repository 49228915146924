<template>
  <zy-table class="zytable" :data="sourceList" :height="560" :objectSpanMethod="objectSpanMethod">
    <el-table-column label="资源类型" align="center" minWidth="76" prop="resourceType" show-overflow-tooltip class-name="rowSpan"></el-table-column>
    <el-table-column label="资源名称" prop="resourceName" minWidth="100" show-overflow-tooltip></el-table-column>
    <el-table-column label="资源计划量" align="right" prop="plannedUsage" minWidth="100" show-overflow-tooltip></el-table-column>
    <el-table-column label="进场时间" minWidth="100" align="center" prop="entryDate" show-overflow-tooltip>
      <template slot-scope="scope">
        {{ formatDate(scope.row.entryDate) }}
      </template>
    </el-table-column>
    <el-table-column label="退场时间" minWidth="100" align="center" prop="exitDate">
      <template slot-scope="scope">
        {{ formatDate(scope.row.exitDate) }}
      </template>
    </el-table-column>
  </zy-table>
</template>

<script>
import components from '@/view/jxyh/components'
import moment from 'moment'
export default {
  ...components(['ZyTable']),
  data() {
    return {}
  },
  props: {
    sourceList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    formatDate(date){
      if(!date) return '——'
      return moment(date).format('YYYY-MM-DD')
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.flitterData(this.sourceList).one[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    /**合并表格的第一列，处理表格数据 */
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.flitterData(this.sourceList).one[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    /**合并表格的第一列，处理表格数据 */
    flitterData(arr) {
      let spanOneArr = []
      let concatOne = 0
      arr.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1)
        } else {
          //注意这里的resourceType是表格绑定的字段，根据自己的需求来改
          if (item.resourceType === arr[index - 1].resourceType) {
            //第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1
            spanOneArr.push(0)
          } else {
            spanOneArr.push(1)
            concatOne = index
          }
        }
      })
      return { one: spanOneArr }
    },
  }
}
</script>

<style lang="less" scoped>
.zytable{
  /deep/ .zy-table .el-table__body-wrapper{
    tr.el-table__row>td.rowSpan:first-child{
      border-right: 1px solid rgba(255, 255, 255, 0.1)
    }
  }
}
</style>